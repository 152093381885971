import { createTheme, CssBaseline, ThemeProvider, } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { getApp, initializeApp } from "firebase/app";
/* import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check"; */
import { connectFirestoreEmulator, doc, getDoc, getFirestore } from "firebase/firestore";
import { getAuth, onAuthStateChanged, connectAuthEmulator } from "firebase/auth";
import TopBar from './components/nav/TopBar';
import React from 'react';
import responsiveFontSizes from '@mui/material/styles/responsiveFontSizes';
import { Box, } from '@mui/system';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import Footer from './components/nav/Footer';
import MetaValues from './components/MetaValues';

/* const app =  */initializeApp({
  apiKey: "AIzaSyCNu9nrOHwLDPtRdlb0LOoORqJmU7KrK0Y",
  authDomain: "rentmanager-d5294.firebaseapp.com",
  projectId: "rentmanager-d5294",
  storageBucket: "rentmanager-d5294.appspot.com",
  messagingSenderId: "596983987464",
  appId: "1:596983987464:web:b88a7bdba0cd1ad55380bd"
});

/* if (process.env.NODE_ENV !== "development") {
  initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider("6LclWhwgAAAAAHFEMM_SfTFeuDvQ8Bo3hRW-FUMl"),
    isTokenAutoRefreshEnabled: true
  });
} */

const auth = getAuth();
const db = getFirestore();
const functions = getFunctions(getApp(), "europe-west3");

if (process.env.NODE_ENV === "development" && false) {
  connectFirestoreEmulator(db, "127.0.0.1", 8080);
  connectAuthEmulator(auth, "http://127.0.0.1:9099", { disableWarnings: false });
  connectFunctionsEmulator(functions, "localhost", 5001);
}

let theme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#386838',
    },
    secondary: {
      main: '#ffffff',
    },
    background: {
      default: '#386838',
      paper: '#294629',
    },
    text: {
      primary: '#ffffff',
    }
  },
  typography: {
    fontFamily: 'Montserrat Variable',
    h1: {
      fontWeight: 700,
      fontSize: "4rem"
    },
    h2: {
      fontWeight: 600,
      fontSize: "3rem"
    },
    h3: {
      fontWeight: 550,
      fontSize: "2rem"
    },
    h6: {
      fontWeight: 600,
      fontSize: "1.2rem"
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: "1.24rem",
      lineHeight: 1.3,
      letterSpacing: "0em"
    }
  },
  components: {
    MuiLink: {
      defaultProps: {
        color: "#ffffff",
      }
    }
  }
})

theme = createTheme(theme, {
  palette: {
    paper: theme.palette.augmentColor({
      color: {
        main: "#294629",
      },
      name: "paper"
    })
  }
})
theme = responsiveFontSizes(theme);

function App() {
  const [loggedIn, setLoggedIn] = React.useState(false);
  const [isAdmin, setIsAdmin] = React.useState(-1);
  const pageTitle = "SL Homes";

  React.useEffect(() => {
    return onAuthStateChanged(auth, user => {
      const realUserLoggedIn = (user && !user.isAnonymous) ? true : false;
      setLoggedIn(realUserLoggedIn);

      if (realUserLoggedIn) {
        getDoc(doc(db, "users", user.uid)).then(snap => {
          if (snap.data().role !== undefined && snap.data().role === "admin") {
            setIsAdmin(1);
          } else {
            setIsAdmin(0);
          }
        })
      } else {
        setIsAdmin(0);
      }
    })
  }, []);


  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh", maxWidth: "100vw" }}>
        <MetaValues title="Stellplätze in Welzow" />
        <TopBar pageTitle={pageTitle} isAdmin={isAdmin} />
        <main style={{ display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 60 }}>
          <Outlet context={[isAdmin]} />
        </main>
        <Footer loggedIn={loggedIn} />
      </Box>
    </ThemeProvider>
  );
}


export default App;
