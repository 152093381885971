import { IconButton, Toolbar, Typography, Link, AppBar, Stack } from "@mui/material";
import useScrollTrigger from '@mui/material/useScrollTrigger';
import MenuIcon from "@mui/icons-material/Menu"
import { useNavigate } from "react-router-dom";
import AppDrawer from "./AppDrawer";
import React from 'react';

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return children
    ? React.cloneElement(children, {
      elevation: trigger ? 4 : 0,
      color: trigger ? "paper" : "primary"
    })
    : null;
}

export default function TopBar(props) {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const navigate = useNavigate();

  function toggleDrawer() {
    setDrawerOpen(prev => !prev);
  }

  return (
    <header>
      <AppDrawer drawerOpen={drawerOpen} toggleDrawer={toggleDrawer} isAdmin={props.isAdmin} />
      <ElevationScroll {...props}>
        <AppBar>
          <Toolbar>
            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{width: "100%"}}>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="Navigationsmenü"
                sx={{ mr: 2 }}
                onClick={toggleDrawer}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" textAlign="center" sx={{ userSelect: "none" }}>
                <Link variant="inherit" color="inherit" component="div" underline="none" textAlign="center" href="/" onClick={(e) => {
                  e.preventDefault();
                  navigate("/");
                }} style={{ cursor: "pointer" }}>
                  {props.pageTitle}
                </Link>
              </Typography>
              <Typography textAlign="end">
                Tel: +49 170 7970885
              </Typography>
            </Stack>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
    </header >
  );
}

